import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

interface ChatTooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  textColor?: string;
}

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipContent = styled.div<{ textColor?: string }>`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 4px;
  background-color: ${theme.colors.black[100]};
  color: ${({ textColor }) => textColor || "white"};
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Averta;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 6000;
  text-align: center;
  white-space: nowrap;

  opacity: 0;
  animation: tooltipFadeIn 0.1s ease-in 2s forwards;

  @keyframes tooltipFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ChatTooltip: React.FC<ChatTooltipProps> = ({ children, content, textColor }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <TooltipWrapper
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={() => setShowTooltip(!showTooltip)}
    >
      {children}
      {showTooltip && (
        <TooltipContent onClick={() => setShowTooltip(false)} textColor={textColor}>
          {content}
        </TooltipContent>
      )}
    </TooltipWrapper>
  );
};
