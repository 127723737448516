import React, { useRef, useEffect } from "react";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import create from "zustand";
import { addYears, format } from "date-fns";
import { v4 as uuidV4 } from "uuid";
import { UPSOLVE_IDENTIFIER_COOKIE } from "@upsolve/shared";
import styled from "styled-components";
import { theme } from "@upsolve/ui";
import { EligibilityResult } from "./eligibilityFunctions";

//TODO: move all this cookie stuff/find out if we can reuse code
const COOKIE_DOMAIN = process.env.NODE_ENV !== "local" ? ".upsolve.org" : "localhost";

const setCookie = (name: string, value: string) => {
  const twoYearsFromNow = addYears(new Date(), 2);
  const cookieParameters = {
    [name]: value,
    domain: COOKIE_DOMAIN,
    expires: format(twoYearsFromNow, "EEE, dd MMM yyyy HH:mm:ss OOO"),
    path: "/",
  };
  document.cookie = Object.entries(cookieParameters)
    .map(([key, value]) => `${key}=${value}`)
    .join("; ");
};

const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.trim().split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
};

const refreshCookie = (name: string) => {
  const oldCookieValue = getCookie(name);
  if (oldCookieValue) {
    setCookie(name, oldCookieValue);
  }
};

// TODO: move to shared
export const findOrCreateAnonymousId = () => {
  const currentAnonymousId = getCookie(UPSOLVE_IDENTIFIER_COOKIE);
  if (currentAnonymousId) {
    refreshCookie(UPSOLVE_IDENTIFIER_COOKIE);
    return currentAnonymousId;
  }

  const anonymousId = uuidV4();
  setCookie(UPSOLVE_IDENTIFIER_COOKIE, anonymousId);
  return anonymousId;
};

export interface IChatMessage {
  id: number;
  sender: "user" | "ai";
  text: string | React.ReactNode;
  createdAt: string;
  articleLink?: string;
  fromPrompt?: boolean;
  wasHelpful?: boolean;
}

interface IChatResponse {
  jobId?: string;
  status: "completed" | "failed" | "processing" | "not_found" | "completed_cleanup";
  progress?: string;
  chatId?: string | null;
  messages?: Array<{
    id: number;
    sender: "user" | "ai";
    text: string;
    articleSuggestions?: Array<{
      title: string;
      url: string;
    }>;
    from_prompt?: boolean;
  }>;
  error?: string;
}

interface IChatJobResponse {
  jobId: string;
  status: "processing";
}

// keep in sync with backend: answerUserQuery.ts
export interface IUserContext {
  optionOfInterest: string;
  input: {
    zipcode: string;
    hasIncome: boolean;
    hasGoodCreditScore: boolean;
    unsecuredDebtEstimate: number;
    householdSize: number;
    passesMeansTest: boolean;
    availableMonthlyFunds: number;
    hasAccessToCapital: boolean;
  };
  // TODO: need to include status quo
  results: {
    statusQuo: {
      interest: number;
      monthlyPayment: number;
      monthsInRepayment: number;
      totalPayment: number;
      canAfford: boolean;
    };
    dmpEligibilityResults: EligibilityResult;
    bankruptcyEligibilityResults: EligibilityResult;
    settlementEligibilityResults: EligibilityResult;
    consolidationEligibilityResults: EligibilityResult;
  };
}
interface ISendMessageParams {
  chatId: number | null;
  message: string;
  deviceId: string;
  userContext: IUserContext;
  fromPrompt: boolean;
}

interface IChatStore {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  chatId: number | null;
  setChatId: (chatId: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  history: IChatMessage[];
  appendToHistory: (message: IChatMessage) => void;
  lastMessageTimestamp: number;
  setLastMessageTimestamp: (timestamp: number) => void;
  processedMessageIds: string[];
  addProcessedMessageId: (id: string) => void;
  clearProcessedMessageIds: () => void;
  updateMessageFeedback: (messageId: number, wasHelpful: boolean | null) => void;
  chatContainerRef: React.RefObject<HTMLDivElement>;
}

const MESSAGE_DELAY = 1500;
const FOLLOW_UP_DELAY = 60000;

const StyledChatIntroMessage = styled.div`
  b {
    margin-bottom: 12px;
  }
  hr {
    border: none;
    border-top: 1px solid ${theme.colors.brand["600"]};
    margin: 16px 0;
  }
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  ul li {
    display: flex; /* Align checkmark and text in one line */
    align-items: flex-start;
  }
  ul li::before {
    content: "✔";
    font-weight: bold;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .check {
    padding-right: 8px;
  }
  p {
    margin-bottom: 8px;
    margin-top: 8px;
  }
`;

const OpeningMessage: React.FC = () => (
  <StyledChatIntroMessage>
    <b>Meet Upsolve's AI Assistant</b>
    <br />
    Debt shouldn't be complicated — we're here to help!
    Ask us anything about bankruptcy or how to use our app.
    When possible, answers come directly from Upsolve's expert-written articles.
    If we can't find a match, we’ll still do our best to give you a helpful, accurate response.
    Just a heads-up: we can only provide general information, not legal advice.
    <hr />
    Our AI Assistant is still learning, so please review all responses. Your questions and the AI answers are
    anonymously recorded. Learn more about how we build reliable and safe AI&nbsp;
    <a href="https://upsolve.org/learn/upsolve-commitments" target="_blank">
      here.
    </a>
  </StyledChatIntroMessage>
);

const getInitialChatHistory = (): IChatMessage[] => {
  return [
    {
      id: Date.now(),
      sender: "ai",
      text: <OpeningMessage />,
      createdAt: new Date().toISOString(),
    },
  ];
};

const useChatStore = create<IChatStore>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  chatId: null,
  setChatId: (chatId: number) => set(() => ({ chatId })),
  isLoading: false,
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  history: getInitialChatHistory(),
  appendToHistory: (message: IChatMessage) =>
    set((state) => ({
      history: [...state.history, message],
    })),
  lastMessageTimestamp: Date.now(),
  setLastMessageTimestamp: (timestamp: number) => set({ lastMessageTimestamp: timestamp }),
  processedMessageIds: [],
  addProcessedMessageId: (id: string) =>
    set((state) => ({
      processedMessageIds: [...state.processedMessageIds, id],
    })),
  clearProcessedMessageIds: () => set({ processedMessageIds: [] }),
  updateMessageFeedback: async (messageId: number, wasHelpful: boolean | null) => {
    try {
      await api.post(`/v1/debtAdvisor/chat/feedback/${messageId}`, { wasHelpful });

      set((state) => ({
        ...state,
        history: state.history.map((msg) => {
          if (msg.id === messageId) {
            return {
              ...msg,
              wasHelpful: wasHelpful ?? undefined,
            };
          }
          return msg;
        }),
      }));
    } catch (error) {
      console.error("Failed to update feedback:", error);
    }
  },
  chatContainerRef: React.createRef<HTMLDivElement>(),
}));

const api = axios.create({
  baseURL: UPSOLVE_API_URL,
});

export const useChat = () => {
  const store = useChatStore();
  const lastTypingTime = useRef<number>(Date.now());
  const followUpTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const deviceId = findOrCreateAnonymousId();
  const queryClient = useQueryClient();
  const currentJobRef = useRef<string | null>(null);
  const failedJobsRef = useRef<Set<string>>(new Set());
  const hasActiveFollowUp = useRef<boolean>(false);

  const handleError = (error: unknown, jobId?: string) => {
    // Mark job as failed if we have a jobId
    if (jobId) {
      failedJobsRef.current.add(jobId);
    }

    const errorMessage: IChatMessage =
      axios.isAxiosError(error) && error.response?.status === 429
        ? {
            id: Date.now(),
            sender: "ai",
            text: "You've sent too many messages. Please wait a moment before trying again.",
            createdAt: new Date().toISOString(),
          }
        : {
            id: Date.now(),
            sender: "ai",
            text: "Sorry, an error occurred. Please try again.",
            createdAt: new Date().toISOString(),
          };

    // Only append error if it's not a duplicate of the last message
    const lastMessage = store.history[store.history.length - 1];
    if (!lastMessage || lastMessage.sender !== "ai" || lastMessage.text !== errorMessage.text) {
      store.appendToHistory(errorMessage);
    }

    store.setIsLoading(false);
    cleanup();
  };

  const cleanup = (): void => {
    if (followUpTimeoutRef.current) {
      clearTimeout(followUpTimeoutRef.current);
    }
    // Reset the follow-up flag when cleaning up
    hasActiveFollowUp.current = false;

    // Remove specific query
    if (currentJobRef.current) {
      queryClient.removeQueries(["chat-message", currentJobRef.current]);
      failedJobsRef.current.add(currentJobRef.current);
    }

    // Cancel any ongoing queries
    queryClient.cancelQueries(["chat-message"]);

    // Reset loading state
    store.setIsLoading(false);
  };

  const cleanupBeforeNewMessage = (): void => {
    cleanup();
    store.clearProcessedMessageIds();
    currentJobRef.current = null;
    failedJobsRef.current.clear();
  };

  useEffect(() => {
    return cleanup;
  }, [queryClient]);

  const sendMessage = useMutation(
    async ({ chatId, message, deviceId, userContext, fromPrompt }: ISendMessageParams) => {
      const response = await api.post<IChatJobResponse>("/v1/debtAdvisor/chat/message", {
        chatId,
        query: message,
        deviceId,
        userContext,
        fromPrompt,
      });
      return response.data;
    },
    {
      // Initial API call failed
      // (e.g. network error creating job or rate limit error)
      onError: (error) => {
        handleError(error);
        store.setIsLoading(false);
      },
    }
  );

  const isNearBottom = () => {
    const container = store.chatContainerRef.current;
    if (!container) {
      return false;
    }

    const threshold = container.clientHeight * 0.8; // 80% of visible height
    const distanceFromBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
    const shouldScroll = distanceFromBottom < threshold;

    return shouldScroll;
  };

  const processMessages = async (data: IChatResponse): Promise<void> => {
    if (!data.messages?.length) return;

    for (let i = 0; i < data.messages.length; i++) {
      const message = data.messages[i];
      if (message.sender === "user") continue;

      const chatMessage: IChatMessage = {
        id: message.id,
        sender: message.sender,
        text: message.text,
        createdAt: new Date().toISOString(),
        articleLink: message.articleSuggestions ? JSON.stringify(message.articleSuggestions) : undefined,
        fromPrompt: message.from_prompt,
      };

      store.appendToHistory(chatMessage);
      // Add delay between messages for natural pacing (no delay after last message since there's no next message to space out)
      if (i < data.messages.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, MESSAGE_DELAY));
      }
    }
    // Update lastTypingTime after AI messages are processed
    lastTypingTime.current = Date.now();
    store.setIsLoading(false);
  };

  useQuery(
    ["chat-message", sendMessage.data?.jobId],
    async () => {
      // Don't fetch if this job already failed
      if (failedJobsRef.current.has(sendMessage.data?.jobId || "")) {
        return null;
      }

      const response = await api.get<IChatResponse>(`/v1/debtAdvisor/chat/message/${sendMessage.data?.jobId}`);
      return response.data;
    },
    {
      enabled: !!sendMessage.data?.jobId && !failedJobsRef.current.has(sendMessage.data?.jobId),
      refetchInterval: (data, query) => {
        const jobId = sendMessage.data?.jobId;

        // Don't poll if no job ID
        if (!jobId) return false;

        // Don't poll if job already failed
        if (failedJobsRef.current.has(jobId)) return false;

        // Only poll if explicitly processing
        if (!data || data.status !== "processing") {
          return false;
        }

        return 1000;
      },
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      staleTime: 30000,
      onSuccess: async (data) => {
        // Return if query was skipped because this job was previously marked as failed in failedJobsRef
        if (!data) return;

        if (data.status === "completed" && data.messages) {
          const messageSignature = data.jobId
            ? `${data.jobId}-${data.messages.length}`
            : `${data.chatId}-${data.messages.length}`;

          if (!store.processedMessageIds.includes(messageSignature)) {
            store.addProcessedMessageId(messageSignature);

            if (!store.chatId && data.chatId) {
              store.setChatId(Number(data.chatId));
            }

            await processMessages(data);

            store.setLastMessageTimestamp(Date.now());
            store.setIsLoading(false);

            // Clear any existing follow-up timeout
            if (followUpTimeoutRef.current) {
              clearTimeout(followUpTimeoutRef.current);
            }

            // Only set a new follow-up if we don't already have one active
            if (!hasActiveFollowUp.current) {
              hasActiveFollowUp.current = true;
              followUpTimeoutRef.current = setTimeout(() => {
                const timeSinceLastType = Date.now() - lastTypingTime.current;
                if (timeSinceLastType > FOLLOW_UP_DELAY) {
                  const followUpMessage: IChatMessage = {
                    id: Date.now(),
                    sender: "ai",
                    text: "Do you have any other questions?",
                    createdAt: new Date().toISOString(),
                  };
                  store.appendToHistory(followUpMessage);
                }
                hasActiveFollowUp.current = false;
              }, FOLLOW_UP_DELAY);
            }
          }
        } else if (data.status === "failed") {
          // Job completed but failed during processing (e.g. AI processing error)
          // Mark job as failed to prevent further polling and show error to user
          handleError(null, sendMessage.data?.jobId);
        }
      },
      onError: (error: unknown) => {
        // Status check API call failed (e.g. network error while polling job status)
        // Mark job as failed to prevent further polling attempts
        handleError(error, sendMessage.data?.jobId);
      },
    }
  );

  const askQuestion = async (messageText: string, userContext: IUserContext, fromPrompt: boolean) => {
    cleanupBeforeNewMessage();
    lastTypingTime.current = Date.now();
    store.setIsOpen(true);

    const questionMessage: IChatMessage = {
      id: Date.now(),
      text: messageText,
      sender: "user",
      createdAt: new Date().toISOString(),
    };

    // Wait for state update to complete
    await Promise.resolve(store.appendToHistory(questionMessage));

    store.setIsLoading(true);

    const response = await sendMessage.mutateAsync({
      chatId: store.chatId,
      message: messageText,
      deviceId,
      userContext,
      fromPrompt,
    });
    currentJobRef.current = response.jobId;
  };

  const updateLastTypingTime = () => {
    lastTypingTime.current = Date.now();
    if (followUpTimeoutRef.current) {
      clearTimeout(followUpTimeoutRef.current);
    }
  };

  return {
    lastTypingTime,
    isOpen: store.isOpen,
    setIsOpen: store.setIsOpen,
    isLoading: store.isLoading,
    history: store.history,
    askQuestion,
    updateMessageFeedback: store.updateMessageFeedback,
    updateLastTypingTime,
    chatContainerRef: store.chatContainerRef,
    isNearBottom,
  };
};
